import React, { useState } from 'react'
import { motion } from 'framer-motion'
import type { TooltipProps } from 'recharts'
import { Area, AreaChart, Line, ReferenceLine, ResponsiveContainer, Tooltip } from 'recharts'

// More dramatic data with March as peak
const monthlyData = [
  { month: 'Jan', value: 5237, label: 'Jan' },
  { month: 'Jan-Mid', value: 6392, label: '' },
  { month: 'Feb', value: 6124, label: 'Feb' },
  { month: 'Feb-Mid', value: 5731, label: '' },
  { month: 'March', value: 8844, label: 'March' },
  { month: 'Mar-Mid', value: 6998, label: '' },
  { month: 'Apr', value: 5763, label: 'Apr' },
  { month: 'Apr-Mid', value: 7145, label: '' },
  { month: 'May', value: 6216, label: 'May' },
].map((item, index, array) => {
  if (index === 0)
    return { ...item, percentageChange: 0 }
  const previousValue = array[index - 1].value
  const percentageChange = ((item.value - previousValue) / previousValue) * 100
  return {
    ...item,
    percentageChange: Math.round(percentageChange),
  }
})

// Topic data for each month
const monthlyTopicData = {
  Jan: [
    { topic: 'Product Features', mentions: 1845, sentiment: 28 },
    { topic: 'Customer Service', mentions: 1632, sentiment: 22 },
    { topic: 'User Interface', mentions: 1254, sentiment: 31 },
    { topic: 'Performance', mentions: 1123, sentiment: 19 },
  ],
  Feb: [
    { topic: 'Product Features', mentions: 1923, sentiment: 34 },
    { topic: 'Customer Service', mentions: 1732, sentiment: 26 },
    { topic: 'User Interface', mentions: 1454, sentiment: 38 },
    { topic: 'Performance', mentions: 1323, sentiment: 24 },
  ],
  March: [
    { topic: 'Product Features', mentions: 2145, sentiment: 42 },
    { topic: 'Customer Service', mentions: 1832, sentiment: 35 },
    { topic: 'User Interface', mentions: 1654, sentiment: 44 },
    { topic: 'Performance', mentions: 1423, sentiment: 32 },
  ],
  Apr: [
    { topic: 'Product Features', mentions: 1876, sentiment: 31 },
    { topic: 'Customer Service', mentions: 1654, sentiment: 28 },
    { topic: 'User Interface', mentions: 1432, sentiment: 36 },
    { topic: 'Performance', mentions: 1289, sentiment: 25 },
  ],
  May: [
    { topic: 'Product Features', mentions: 1967, sentiment: 33 },
    { topic: 'Customer Service', mentions: 1723, sentiment: 29 },
    { topic: 'User Interface', mentions: 1521, sentiment: 37 },
    { topic: 'Performance', mentions: 1345, sentiment: 27 },
  ],
}

function CustomTooltip({ active, payload }: TooltipProps<number, string>) {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white px-3 py-2 shadow-lg rounded-lg border border-gray-100">
        <p className="text-sm font-medium text-gray-900">{payload[0].value?.toLocaleString()}</p>
      </div>
    )
  }
  return null
}

const PercentageChange: React.FC<{ value: number }> = ({ value }) => {
  if (value > 0) {
    return (
      <span className="flex items-center text-sm text-emerald-500 font-medium">
        <span className="text-lg mr-0.5">▲</span>
        {value}
        %
      </span>
    )
  }
  else if (value < 0) {
    return (
      <span className="flex items-center text-sm text-red-500 font-medium">
        <span className="text-lg mr-0.5">▼</span>
        {Math.abs(value)}
        %
      </span>
    )
  }
  return (
    <span className="flex items-center text-sm text-gray-400 font-medium">
      -0%
    </span>
  )
}

export default function StatsExample() {
  const [hoveredValue, setHoveredValue] = useState<number | null>(null)
  const [hoveredMonth, setHoveredMonth] = useState<string | null>(null)
  const [selectedMonth, setSelectedMonth] = useState('March')
  const [activeIndex, setActiveIndex] = useState(4) // March is at index 4 now

  const activeMonth = hoveredMonth || selectedMonth
  const displayValue = hoveredValue || monthlyData[activeIndex].value
  const displayPercentage = hoveredMonth
    ? monthlyData.find(d => d.month === hoveredMonth)?.percentageChange
    : monthlyData.find(d => d.month === selectedMonth)?.percentageChange

  // Get the current topic data based on selected/hovered month
  // Always use the main month data, stripping any "-Mid" suffix
  const mainMonth = activeMonth.split('-')[0] as keyof typeof monthlyTopicData
  const currentTopicData = monthlyTopicData[mainMonth] || monthlyTopicData.March

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full max-w-4xl mx-auto mt-8 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 px-4 md:px-0"
    >
      {/* Left Panel */}
      <div className="bg-white rounded-3xl p-4 md:p-8 shadow-sm ring-1 ring-gray-200">
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-left font-medium text-gray-500 pb-6">Topic</th>
              <th className="text-center font-medium text-gray-500 pb-6">Mentions</th>
              <th className="text-right font-medium text-gray-500 pb-6 pr-2">Sentiment</th>
            </tr>
          </thead>
          <tbody className="text-[15px]">
            {currentTopicData?.map((topic) => (
              <tr key={topic.topic} className="border-t border-gray-100">
                <td className="py-4 text-gray-900 font-normal">{topic.topic}</td>
                <td className="py-4 text-gray-900 text-center">{topic.mentions.toLocaleString()}</td>
                <td className="py-4 text-emerald-500 font-medium text-right pr-2">
                  +
                  {topic.sentiment}
                  %
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Right Panel */}
      <div className="bg-white rounded-3xl p-4 md:p-8 shadow-sm ring-1 ring-gray-200">
        <div>
          <p className="text-sm text-gray-500 mb-1">
            <span className="font-medium">Total Topic Mentions </span>
          </p>
          <div className="flex items-start gap-2 mb-4 md:mb-8">
            <h3 className="text-[32px] md:text-[42px] font-semibold text-gray-900">
              {displayValue.toLocaleString()}
            </h3>
            <div className="mt-2 md:mt-3">
              {displayPercentage !== undefined && (
                <PercentageChange value={displayPercentage} />
              )}
            </div>
          </div>
        </div>

        <div className="h-36 md:h-44 -mx-2">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={monthlyData}
              margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
              onMouseMove={(state) => {
                if (state && state.activePayload && state.activePayload[0]) {
                  const payload = state.activePayload[0].payload
                  setHoveredValue(payload.value)
                  setHoveredMonth(payload.month)
                }
              }}
              onMouseLeave={() => {
                setHoveredValue(null)
                setHoveredMonth(null)
              }}
            >
              <defs>
                <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="rgb(59, 130, 246)" stopOpacity={0.12} />
                  <stop offset="100%" stopColor="rgb(59, 130, 246)" stopOpacity={0.02} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="value"
                stroke="#0284c7"
                fill="url(#colorValue)"
                strokeWidth={1.5}
                dot={() => null}
                activeDot={
                  hoveredMonth ? { r: 4, fill: '#0284c7', strokeWidth: 0 } : undefined
                }
              />
              {hoveredMonth && (
                <ReferenceLine
                  key={`ref-${hoveredMonth}`}
                  x={hoveredMonth}
                  stroke="#0284c7"
                  strokeWidth={1}
                  strokeDasharray="3 3"
                  segment={[{ x: 0, y: 0 }, { x: 0, y: 1 }]}
                  />
              )}
              <Tooltip
                content={<CustomTooltip />}
                cursor={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div className="flex justify-between mt-3 text-sm font-normal">
          {monthlyData.filter(item => item.label).map(item => (
            <button
              key={item.month}
              onClick={() => {
                setSelectedMonth(item.month)
                setActiveIndex(monthlyData.findIndex(d => d.month === item.month))
              }}
              className={
                item.month === activeMonth
                  ? 'text-primary-600 font-medium px-2 md:px-3 py-1 bg-primary-100 rounded-full text-xs md:text-sm'
                  : 'text-gray-500 px-2 md:px-3 py-1 hover:bg-gray-50 rounded-full text-xs md:text-sm'
              }
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </motion.div>
  )
}
