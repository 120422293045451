import React from 'react'

interface BackgroundPatternProps {
  children: React.ReactNode
}

function BackgroundPattern({ children }: BackgroundPatternProps) {
  // Use fixed values instead of random generation
  const generatePattern = () => {
    const patternSize = 4
    return Array.from({ length: patternSize * patternSize }, (_, i) => ({
      x: ((i % patternSize) * 10) + 5,
      y: (Math.floor(i / patternSize) * 10) + 5,
      opacity: 0.4 + (0.2 * ((i % (patternSize * 2)) / (patternSize * 2))),
    }))
  }

  // Pattern is now deterministic and will be the same on server and client
  const dots = generatePattern()

  return (
    <div className="mx-4 sm:mx-8 md:mx-10 lg:mx-14 my-6 relative rounded-3xl ring-1 ring-gray-200 dark:ring-gray-800">
      <div className="absolute inset-0">
        <div className="size-full overflow-hidden">
          <svg
            className="size-full"
            width="100%"
            height="100%"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="dotPattern"
                width="40"
                height="40"
                patternUnits="userSpaceOnUse"
              >
                {dots.map((dot, index) => (
                  <circle
                    key={index}
                    cx={dot.x}
                    cy={dot.y}
                    r="1"
                    fill="#dddeee"
                    opacity={dot.opacity.toFixed(2)}
                  />
                ))}
              </pattern>

              <radialGradient
                id="maskGradient"
                cx="0.5"
                cy="0.5"
                r="0.5"
              >
                <stop offset="0%" stopColor="white" stopOpacity="1" />
                <stop offset="50%" stopColor="white" stopOpacity="0.5" />
                <stop offset="100%" stopColor="white" stopOpacity="0" />
              </radialGradient>

              <mask id="radialMask">
                <circle
                  cx="50%"
                  cy="50%"
                  r="35%"
                  fill="url(#maskGradient)"
                  filter="url(#blur)"
                >
                  <animate
                    attributeName="r"
                    values="35%;40%;35%"
                    dur="10s"
                    repeatCount="indefinite"
                  />
                </circle>
              </mask>

              <filter id="blur">
                <feGaussianBlur stdDeviation="40" />
              </filter>
            </defs>

            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              fill="url(#dotPattern)"
              mask="url(#radialMask)"
            />
          </svg>
        </div>
      </div>

      <div className="relative">
        {children}
      </div>
    </div>
  )
}

export default BackgroundPattern
